import { map, Observable } from 'rxjs';
import { CompanyDto, ResourcesResponseDto, RolesResponseDto } from '../services/rolesapi/rolesapi.services';
import { RoleModel } from '../services/u2api.services';
import { TransformUtilities } from './utilities.transform';

export class GetRoleByIdTransform {
  static transformInput(id: string): number {
    const base10 = 10;
    return parseInt(id, base10);
  }

  static transformOutput(
    observableResponse: Observable<RolesResponseDto>,
    allCompanies?: CompanyDto[],
    allResources?: ResourcesResponseDto[],
  ): Observable<RoleModel> {
    return observableResponse.pipe(
      map(rolesResponseDto => {
        const roleModel = new RoleModel();
        roleModel.roleId = rolesResponseDto.roleId;
        roleModel.roleName = rolesResponseDto.name;
        roleModel.canImpersonate = rolesResponseDto.canImpersonate;
        roleModel.companies = TransformUtilities.mapCompanyData(rolesResponseDto.companies, allCompanies);

        roleModel.screens = TransformUtilities.mapResourcePermissionsToScreens(
          rolesResponseDto.permissions,
          allResources,
          rolesResponseDto?.application?.name,
        );

        roleModel.hasAllCompanies = TransformUtilities.hasAllCompanies(rolesResponseDto.companies, allCompanies);
        return roleModel;
      }),
    );
  }
}
